import "./modules/section.css";
import {
  Accordion,
  Col,
  Navbar,
  Offcanvas,
  Row,
  Tabs,
  Tab,
} from "react-bootstrap";
import Section from "./modules/Section";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useState, useEffect } from "react";
import { mapValues, groupBy } from "lodash";
import { collection, getDocs, where, query } from "firebase/firestore";
import { db } from "./modules/firestore";
import ExampleData from "./ExampleData";

function App() {
  const [show, setShow] = useState(false);
  const [showToast, setShowToast] = useState(true);
  const [key, setKey] = useState();
  const [data, setData] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getCategory = async () => {
    await getDocs(query(collection(db, "product"), where("visible", "==", true)))
      .then((res) => {
        const newData = res.docs.map((doc) => (doc.data().visible ? { ...doc.data(), id: doc.id } : {}));
        setData(newData);
      })
  }
  useEffect(() => {
    getCategory();
    //setData(ExampleData);
  }, []);

  let foodAndDrinks = mapValues(groupBy(data, 'food'));
  let food = mapValues(groupBy(foodAndDrinks["true"], "category"))
  let foodItems = Object.keys(food).sort();
  let drink = mapValues(groupBy(foodAndDrinks["false"], "category"))
  let drinkItems = Object.keys(drink).sort();

  const notify = () => { toast("Servicio, pan y picos, 0.50€ por persona", { toastId: 'success1' }); setShowToast(false) };
  return (
    <>
      {showToast ? notify() : null}
      <ToastContainer
        position="bottom-center"
        autoClose={false}
        limit={1}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="dark"
      />
      <img src="/logo512.png" className="imgFondo" alt="" />
      <Navbar expand="lg" className="fondo">
        <Col style={{ display: "flex", justifyContent: "center" }}>
          <h1 onClick={handleShow} className="space">
            <img
              src="/letras.png"
              style={{ maxWidth: "100%" }}
              alt="Mirringa Mirronga"
            />
          </h1>

          <Offcanvas style={{ heigth: "50%" }} show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                <h1>¿Qué es mirringa mirronga?</h1>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <h3>
                El nombre Mirringa Mirronga viene de una fábula Colombiana, que
                dejamos por aquí por si quereis leerlo mientras llega la comida
              </h3>
              <p>
                Mirringa Mirronga, la gata candonga va a dar un convite jugando
                escondite, y quiere que todos los gatos y gatas no almuercen
                ratones ni cenen con ratas. “A ver mis anteojos, y pluma y
                tintero, y vamos poniendo las cartas primero. Que vengan las
                Fuñas y las Fanfarriñas, y Ñoño y Marroño y Tompo y sus niñas.
                ”Ahora veamos qué tal la alacena. Hay pollo y pescado, ¡la cosa
                está buena! Y hay tortas y pollos y carnes sin grasa. ¡Qué
                amable señora la dueña de casa! “Venid mis michitos Mirrín y
                Mirrón. Id volando al cuarto de mamá Fogón por ocho escudillas y
                cuatro bandejas que no estén rajadas, ni rotas ni viejas. ”Venid
                mis michitos Mirrón y Mirrín, traed la canasta y el dindirindín,
                ¡y zape, al mercado! que faltan lechugas y nabos y coles y arroz
                y tortuga. "Decid a mi amita que tengo visita, que no venga a
                verme, no sea que se enferme que mañana mismo devuelvo sus
                platos, que agradezco mucho y están muy baratos. “¡Cuidado,
                patitas, si el suelo me embarran ¡Que quiten el polvo, que
                frieguen, que barran ¡Las flores, la mesa, la sopa!... ¡Tilín!
                Ya llega la gente. ¡Jesús, qué trajín!”. Llegaron en coche ya
                entrada la noche señores y damas, con muchas zalemas, en grande
                uniforme, de cola y de guante, con cuellos muy tiesos y frac
                elegante. Al cerrar la puerta Mirriña la tuerta en una cabriola
                se mordió la cola, mas olió el tocino y dijo “¡Miaao! ¡Este es
                un banquete de pipiripao!” Con muy buenos modos sentáronse
                todos, tomaron la sopa y alzaron la copa; el pescado frito
                estaba exquisito y el pavo sin hueso era un embeleso. De todo
                les brinda Mirringa Mirronga: – “¿Le sirvo pechuga?”– “Como
                usted disponga, y yo a usted pescado, que está delicado”. –
                "Pues tanto le peta, no gaste etiqueta: “Repita sin miedo". Y él
                dice:– “Concedo”. Mas ¡ay! que una espina se le atasca indina, y
                Ñoña la hermosa que es habilidosa metiéndole el fuelle le dice:
                “¡Resuelle!” Mirriña a Cuca le golpeó en la nuca y pasó al
                instante la espina del diantre, sirvieron los postres y luego el
                café, y empezó la danza bailando un minué. Hubo vals, lanceros y
                polka y mazurca, y Tompo que estaba con máxima turca, enreda en
                las uñas el traje de Ñoña y ambos van al suelo y ella se
                desmoña. Maullaron de risa todos los danzantes y siguió el jaleo
                más alegre que antes, y gritó Mirringa: “¡Ya cerré la puerta!
                ¡Mientras no amanezca, ninguno deserta!” Pero ¡qué desgracia!
                entró doña Engracia y armó un gatuperio un poquito serio
                dándoles chorizo de tío Pegadizo para que hagan cenas con tortas
                ajenas.
              </p>
            </Offcanvas.Body>
          </Offcanvas>
        </Col>
      </Navbar>

      <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="nav-fill">
        <Tab eventKey="tabla" title="Comida" style={{ width: "100%" }}>
          <Accordion className="fondo" flush>
            {foodItems.map((element) => (
              <Section el={food[element].sort((x, y) => { if (x.name === y.name) { return 0; } if (x.name < y.name) { return -1; } return 1; })} />
            ))}
          </Accordion>
        </Tab>
        <Tab eventKey="list" title="Bebida">
          <Accordion className="fondo" flush>
            {drinkItems.map((element) => (
              <Section el={drink[element].sort((x, y) => { if (x.name === y.name) { return 0; } if (x.name < y.name) { return -1; } return 1; })} />
            ))}
          </Accordion>
        </Tab>
      </Tabs>
      <footer
        style={{ backgroundColor: "#000066", color: "white", padding: "2em" }}
      >
        <Row style={{ marginTop: "1em" }}>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <img
              src="/iconos/phone.png"
              alt="Llámanos"
              style={{ maxWidth: "80%", maxHeight: "80%" }}
              onClick={() => {
                window.location.href = 'tel:954711935';
              }}
            />
          </Col>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <img
              src="/iconos/instagram.png"
              alt="Instagram"
              style={{ maxWidth: "80%", maxHeight: "80%" }}
              onClick={() =>
                window.open(
                  "https://www.instagram.com/mirringamirrongarestaurante/",
                  "_blank"
                )
              }
            />
          </Col>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <img
              src="/iconos/envelope.png"
              alt="Contáctanos"
              style={{ maxWidth: "80%", maxHeight: "80%" }}
              onClick={() => {
                window.location.href = 'mailto:barmirringamirronga@gmail.com';
              }}

            />
          </Col>
        </Row>
        <Row style={{ marginTop: "2em" }}>
          <iframe title="Nuestra ubicación:" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3169.7579483560307!2d-5.977379284837372!3d37.39555617983033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd126f4e50eea1e5%3A0x81b0591014663f43!2sRestaurante%20Mirringa%20Mirronga!5e0!3m2!1ses!2ses!4v1670413955215!5m2!1ses!2ses" style={{ "border": "0", "minWidth": "90vw", "minHeight": "90vw" }} allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </Row>
      </footer>
    </>
  );
}

export default App;
