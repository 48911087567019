import { Container, Modal, Row, Col, Stack } from "react-bootstrap";

const ParticipaPaga = (props) => {
  if (props.prod === null) {
    return <></>;
  } else {
    return (
      <Modal
        show={props.show}
        onHide={props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.prod.name} - alérgenos
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            
            {props.prod.description === "" ? (
                    ""
                  ) : (
                    <Row style={{marginBottom:"2em"}}>
                      {props.prod.description}
                      <br />
                      </Row>
                  )}
            
            <Row>

              {/* <Stack direction="horizontal" gap={1}> */}
                {props.prod.alergies.map((a) => (
                  <Col className="center-horizontal">
                  <img
                    width="50px"
                    alt={a}
                    src={`/alergenos/${a}.png`}
                  ></img>
                  </Col>
                ))}
              {/* </Stack> */}
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }
};
export default ParticipaPaga;
