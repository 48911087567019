import React from "react";
import {
  Accordion,
  ListGroup,
  Stack,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import { useState } from "react";
import "./section.css";
import InfoModal from "./InfoModal";

const Section = (data) => {
  const [modal, setModal] = useState(0);
  const [product, setProduct] = useState(null);

  function verInfo(p) {
    setProduct(p);
    setModal(1);
  }
  return (
    <Accordion.Item eventKey={data.el[0].category}>
      <Accordion.Header style={{ color: "#FFFFFF !important" }}>
        <Row>
          <Col>
            <h1 id={data.el[0].category}>{data.el[0].category.split("-")[1]}</h1>
          </Col>
        </Row>
      </Accordion.Header>
      <Accordion.Body>
        <ListGroup as="ol">
          {data.el.map((el) => (
            <ListGroup.Item
              as="li"
              className={"d-flex justify-content-between align-items-start fondoItem " + (el !== data.el[data.el.length-1] ? "separator" : "")}
            >
              <Row style={{ width: "100%" }}>

                <div className="ms-2 me-auto" style={{paddingRight:"2em"}}>
                  <div >{el.name}</div>
                  {Array.from({ length: el.price.length }, (v, i) => i).map(
                    (p) => (
                      <span className="fw-bold precio">
                        {el.price[p]}
                        {p < el.price.length - 1 ? " / " : ""}
                      </span>
                    )
                  )}
                </div>

                {(el.alergies.length === 0 || el.alergies[0] === "") && el.description === "" ? (
                  <></>
                ) : (
                  <img
                    src="/iconos/info.png"
                    alt="i"
                    className="imagenInfo"
                    onClick={() => verInfo(el)}
                  />
                )}
              </Row>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Accordion.Body>
      <InfoModal
        show={modal}
        prod={product === null ? null : product}
        onHide={() => {
          setModal(0);
          setProduct(null);
        }}
      />
    </Accordion.Item>
  );
};

export default Section;
